//React
import { useContext, useMemo } from "react";
//Componentes externos
import { Button, Container } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import { faSquarePlus, faPen, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Componentes
import { Loading } from "../../components/Loading/Loading";
import CustomTable from "../../components/CustomTable/CustomTable";
//Hooks externos
import { useNavigate, useSearchParams } from "react-router-dom";
//Hooks
import { useGetData } from "../../hooks/useGetData";
import { useLoginInvitado } from "../../hooks/useLoginInvitado";
import { useSearchParamsValidation } from "../../hooks/useSearchParamsValidation";
//Context
import { AuthContext } from "../../context/AuthContext";
//Interfaces
import { ReaccionInterface } from "../../interfaces/ReaccionInterface";
import { MovimientoInterface } from "../../interfaces/MovimientoInterface";
//Funciones
import { getFechaFormateada } from "../../functions/functions";
//Estilo
const classes = require("./ReaccionListaInvitadoScreen.module.css").default;

/**
 * ReaccionListaInvitadoScreen
 * @description: Pantalla pública que muestra la lista de reacciones de un movimiento de SkyOne.
 * @date 09/04/2024.
 * @returns JSX del Screen.
 */
export const ReaccionListaInvitadoScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook que obtiene el context
  const authContext = useContext(AuthContext);
  //Hook que valida si el token enviado por la url es válido
  const isValid = useSearchParamsValidation();
  //Hook para el manejo del login
  const loginInvitado = useLoginInvitado();
  //Hook para obtener los parámetros de búsqueda de la url
  const [searchParams] = useSearchParams();
  //Se encarga de traer la información
  const queryMovimiento = useGetData<MovimientoInterface | undefined>({
    url: `/movimiento/${searchParams.get(
      "movimiento_externo_id"
    )}/sistema/${searchParams.get("sistema_id")}/reaccion_lista`,
    name: [
      "movimiento",
      `${searchParams.get("movimiento_externo_id")}`,
      "sistema",
      `${searchParams.get("sistema_id")}`,
    ],
    condition: !authContext.isLoggedIn && isValid,
  });
  //Se encarga de traer la información
  const queryMovimientoLogin = useGetData<MovimientoInterface | undefined>({
    url: `/movimiento/${authContext.movimientoExternoId}/sistema/${authContext.sistemaId}/reaccion_lista`,
    name: [
      "movimiento",
      `${authContext.movimientoExternoId}`,
      "sistema",
      `${authContext.sistemaId}`,
    ],
    condition: authContext.isLoggedIn,
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* ------------------------------ Columnas --------------------------------*/
  const columns = useMemo<ColumnDef<ReaccionInterface>[]>(
    () => [
      {
        header: "Folio",
        accessorFn: (row) =>
          `CIO/R/${row.id}-${new Date(row.created_at ?? "").getFullYear()}`,
        cell: (info) =>
          "CIO/R/" +
          info.row.original.id +
          "-" +
          new Date(info.row.original.created_at ?? "").getFullYear(),
      },
      {
        header: "Causa",
        cell: (info) =>
          info.row.original.causa_reaccion
            ? info.row.original.causa_reaccion.nombre
            : "",
        accessorFn: (row) =>
          row.causa_reaccion ? row.causa_reaccion.nombre : "",
      },
      {
        header: "Placa",
        accessorKey: "movimiento.placa",
      },
      {
        header: "Transportista",
        accessorKey: "movimiento.transportista",
      },
      {
        header: "Estatus",
        accessorKey: "estatus_reaccion.nombre",
      },
      {
        header: "Fecha de creación",
        accessorFn: (row) => getFechaFormateada(row.created_at),
        cell: (info) => getFechaFormateada(info.row.original.created_at),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            {/** Botón para editar*/}
            <Button
              title="Editar reacción"
              className={classes.boton}
              onClick={async () => {
                if (!authContext.isLoggedIn) {
                  await loginInvitado(
                    searchParams.get("movimiento_externo_id") ?? "",
                    searchParams.get("sistema_id") ?? ""
                  );
                }
                navigate(`/reaccion/editar/${info.row.original.id}`);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para listar eventos*/}
            <Button
              title="Listar eventos"
              className={classes.boton}
              onClick={async () => {
                if (!authContext.isLoggedIn) {
                  await loginInvitado(
                    searchParams.get("movimiento_externo_id") ?? "",
                    searchParams.get("sistema_id") ?? ""
                  );
                }
                navigate(`/evento/lista/${info.row.original.id}`);
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
          </fieldset>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, loginInvitado, searchParams, authContext.isLoggedIn]
  );
  /* -------------------------------- return --------------------------------*/
  if (queryMovimiento.isLoading || queryMovimientoLogin.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container fluid className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>{`Reacciones del movimiento ${
          queryMovimiento.data?.id ?? queryMovimientoLogin.data?.id
        }`}</h2>
      </div>
      <div className={classes.containerTable}>
        <Button
          title="Agregar una nueva reacción"
          className={classes.boton}
          onClick={async () => {
            if (!authContext.isLoggedIn) {
              await loginInvitado(
                searchParams.get("movimiento_externo_id") ?? "",
                searchParams.get("sistema_id") ?? ""
              );
            }
            if (queryMovimiento.data || queryMovimientoLogin.data) {
              navigate(
                `/reaccion/crear?movimiento_id=${
                  queryMovimiento.data?.id ?? queryMovimientoLogin.data?.id
                }&latitud=${searchParams.get("latitud") ?? ""}&longitud=${
                  searchParams.get("longitud") ?? ""
                }`
              );
            } else {
              navigate(
                `/reaccion/crear?${new URLSearchParams(
                  Object.fromEntries(searchParams)
                ).toString()}`
              );
            }
          }}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear reacción
        </Button>
      </div>
      <CustomTable
        {...{
          data: authContext.isLoggedIn
            ? queryMovimientoLogin.data?.reacciones
            : queryMovimiento.data?.reacciones,
          columns,
        }}
      />
    </Container>
  );
};
