import React, { useState, useCallback, useMemo } from 'react';
import { UsuarioLoginInterface } from '../interfaces/UsuarioLoginInterface';

type AuthContextProps = {
  token: string | null;
  usuario: UsuarioLoginInterface | null;
  rol: string | null;
  movimientoExternoId?: string;
  sistemaId?: string;
  isLoggedIn: boolean;
  login: (
    usuario: UsuarioLoginInterface | null,
    token: string | null,
    rol: string | null,
    movimiento_externo_id?: string,
    sistema_id?: string
  ) => void;
  logout: () => void;
};

export const AuthContext = React.createContext({} as AuthContextProps);

const getInitialState = () => ({
  token: localStorage.getItem('token'),
  rol: localStorage.getItem('rol'),
  usuario: localStorage.getItem('usuario') 
    ? JSON.parse(localStorage.getItem('usuario') || '{}')
    : null,
  movimientoExternoId: localStorage.getItem('movimientoExternoId') || undefined,
  sistemaId: localStorage.getItem('sistemaId') || undefined,
});

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState(getInitialState);

  const userIsLoggedIn = !!authState.token;

  const logoutHandler = useCallback(() => {
    setAuthState({
      token: null,
      usuario: null,
      rol: null,
      movimientoExternoId: undefined,
      sistemaId: undefined,
    });
    
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('rol');
    localStorage.removeItem('movimientoExternoId');
    localStorage.removeItem('sistemaId');
  }, []);

  const loginHandler = useCallback((
    usuario: UsuarioLoginInterface | null,
    token: string | null,
    rol: string | null,
    movimiento_externo_id?: string,
    sistema_id?: string
  ) => {
    setAuthState({
      token,
      usuario,
      rol,
      movimientoExternoId: movimiento_externo_id,
      sistemaId: sistema_id,
    });

    if (token) localStorage.setItem('token', token);
    if (rol) localStorage.setItem('rol', rol);
    if (usuario) localStorage.setItem('usuario', JSON.stringify(usuario));
    if (movimiento_externo_id) localStorage.setItem('movimientoExternoId', movimiento_externo_id);
    if (sistema_id) localStorage.setItem('sistemaId', sistema_id);
  }, []);

  const contextValue = useMemo(() => ({
    token: authState.token,
    rol: authState.rol,
    usuario: authState.usuario,
    movimientoExternoId: authState.movimientoExternoId,
    sistemaId: authState.sistemaId,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  }), [authState, loginHandler, logoutHandler, userIsLoggedIn]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};