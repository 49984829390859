//React/react native
import { useContext, useEffect, useState } from 'react';
//Componentes externos
import { Button, Container } from 'react-bootstrap';
import {
  faClapperboard,
  faFileCircleXmark,
  faFilePdf,
  faImage,
  faSquarePlus,
  faVolumeHigh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Componentes
import { Loading } from '../../components/Loading/Loading';
import TimelineCard from '../../components/TimelineCard/TimelineCard';
//Hooks externos
import { useNavigate, useParams } from 'react-router-dom';
//Hooks
import { useGetData } from '../../hooks/useGetData';
import { usePdf } from '../../hooks/usePdf';
//Funciones
import { getFechaFormateada } from '../../functions/functions';
//Context
import { AuthContext } from '../../context/AuthContext';
//Interfaces
import { EventoInterface } from '../../interfaces/EventoInterface';
//Estilo
const classes = require('./EventoListaScreen.module.css').default;

/**
 * EventoListaScreen
 * @description: Pantalla que muestra la lista de eventos
 * @date 16/10/2023.
 * @returns JSX del Screen.
 */
export const EventoListaScreen = () => {
  /* ----------------------------- useState's -------------------------------*/
  const [loadingPdf, setLoadingPdf] = useState(false);
  /* ----------------------------- Parámetros -------------------------------*/
  const { reaccion_id } = useParams();
  /* ----------------------------- Hooks ------------------------------------*/
  //Context
  const authContext = useContext(AuthContext);
  //PDF
  const { mostrarPDF } = usePdf(setLoadingPdf);
  //Se encarga de traer la información
  const queryEventos = useGetData({
    url: `/evento?reaccion_id[eq]=${reaccion_id}&includeAll=true`,
    name: ['evento', 'reaccion', `${reaccion_id}`],
    hasWebSocket: true,
  });

  useEffect(() => {
    if (queryEventos.data) transformData(queryEventos.data);
  }, [queryEventos.data]);

  const [eventos, setEventos] = useState([]);
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* ------------------------------ Funciones -------------------------------*/
  const transformData = (data: any) => {
    let accionesPorFecha: any = {};
    let prevFecha: string | null = null;
    data.forEach((evento: EventoInterface) => {
      if (prevFecha !== evento.created_at.toString().substring(0, 10)) {
        prevFecha = evento.created_at.toString().substring(0, 10);
        accionesPorFecha[prevFecha] = [evento];
      } else {
        accionesPorFecha[prevFecha] = [...accionesPorFecha[prevFecha], evento];
      }
    });
    setEventos(accionesPorFecha);
  };

  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryEventos.isLoading || loadingPdf) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>Eventos</h2>
      </div>
      <div className={classes.containerTable}>
        <Button
          title="Crear evento"
          className={classes.boton}
          onClick={() => navigate(`/evento/crear/${reaccion_id}`)}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear evento
        </Button>
        {/** Botón para descargar informe*/}
        {authContext.rol === 'Invitado' && (
          <Button
            title="PDF"
            className={classes.boton}
            onClick={() => {
              mostrarPDF(Number(reaccion_id));
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </Button>
        )}
      </div>
      <section className={`${classes.timelineArea} section_padding_130`}>
        <div className="row">
          <div className="col-12">
            <div className="apland-timeline-area">
              {Object.entries(eventos).map(([key, ev]: any) => (
                <div className={classes.singleTimelineArea} key={key}>
                  <div
                    className={`${classes.timelineDate} wow fadeInLeft`}
                    data-wow-delay="0.1s"
                    style={{
                      visibility: 'visible',
                      animationDelay: '0.1s',
                      animationName: 'fadeInLeft',
                    }}
                  >
                    <p>{key}</p>
                  </div>
                  <div className="row">
                    {ev.map((evento: EventoInterface) => (
                      <TimelineCard
                        key={evento.id}
                        id={evento.id}
                        titulo={`${evento.comentario}`}
                        descripcion={`A las ${getFechaFormateada(evento?.created_at)} por ${evento?.usuario?.nombre} ${evento?.usuario?.apellido_paterno}`}
                        icono={
                          <FontAwesomeIcon
                          icon={
                            evento?.images && 
                            evento?.documents && 
                            evento?.audios && 
                            evento?.images?.length > 0 && 
                            evento.documents?.length > 0 && 
                            evento.audios?.length > 0
                              ? faClapperboard
                              : evento?.images && evento?.images?.length > 0
                              ? faImage
                              : evento?.documents && evento.documents?.length > 0
                              ? faVolumeHigh
                              : evento?.audios && evento.audios?.length > 0
                              ? faVolumeHigh
                              : faFileCircleXmark
                          }
                          
                            style={{
                              marginTop: '4px',
                              fontSize: '20px',
                              color: 'white',
                            }}
                          />
                        }
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};
