//React
import { useMemo, useState } from 'react';
//Componentes externos
import { Button, Container } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import {
  faSquarePlus,
  faPen,
  faCheck,
  faList,
  faUndo,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Componentes
import { Loading } from '../../components/Loading/Loading';
import CustomTable from '../../components/CustomTable/CustomTable';
//Hooks externos
import { useNavigate, useParams } from 'react-router-dom';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
import { usePdf } from '../../hooks/usePdf';
//Interfaces
import { ReaccionInterface } from '../../interfaces/ReaccionInterface';
//Funciones
import { getFechaFormateada } from '../../functions/functions';
//Estilo
const classes = require('./ReaccionListaScreen.module.css').default;

/**
 * ReaccionListaScreen
 * @description: Pantalla que muestra la lista de reacciones generales
 * @date 16/10/2023.
 * @returns JSX del Screen.
 */
export const ReaccionListaScreen = () => {
  /* ----------------------------- useState's -------------------------------*/
  const [loadingPdf, setLoadingPdf] = useState(false);
  /* ----------------------------- Hooks ------------------------------------*/
  //PDF
  const { mostrarPDF } = usePdf(setLoadingPdf);
  //Hook para obtener los parámetros de la url
  const { estatus_reaccion_id } = useParams();
  //Hook para cambiar el estatus del registro
  const mutateEstatusReaccion = useMutationData({
    url: `/update_estatus_reaccion`,
    reload: ['reaccion', 'estatus_reaccion'],
  });
  //Se encarga de traer la información
  const queryReaccion = useGetData<ReaccionInterface[]>({
    url: `/reaccion?includeAll=true&estatus_reaccion_id[eq]=${
      estatus_reaccion_id ?? '1'
    }`,
    name: ['reaccion', 'estatus_reaccion', estatus_reaccion_id ?? '1'],
    hasWebSocket: true,
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* ------------------------------ Funciones -------------------------------*/
  const columns = useMemo<ColumnDef<ReaccionInterface>[]>(
    () => [
      {
        header: 'Folio',
        accessorKey: 'id',
        type: 'eq',
        cell: (info) =>
          'CIO/R/' +
          info.row.original.id +
          '-' +
          new Date(info.row.original.created_at ?? '').getFullYear(),
        footer: (props) => props.column.id,
      },
      {
        header: 'Lugar de reaccion',
        accessorKey: 'lugar_reaccion',
      },
      {
        header: 'Causa',
        cell: (info) =>
          info.row.original.causa_reaccion
            ? info.row.original.causa_reaccion.nombre
            : '',
        accessorFn: (row) =>
          row.causa_reaccion ? row.causa_reaccion.nombre : '',
      },
      {
        header: "Placa",
        accessorKey: "movimiento.placa",
      },
      {
        header: "Transportista",
        accessorKey: "movimiento.transportista",
      },
      {
        header: 'Sistema',
        accessorKey: 'movimiento.sistema.nombre',
        cell: (info) => info.row.original.movimiento?.sistema?.nombre,
        footer: (props) => props.column.id,
      },
      {
        header: 'Estatus',
        accessorKey: 'estatus_reaccion.nombre',
      },
      {
        header: "Fecha de creación",
        accessorFn: (row) => getFechaFormateada(row.created_at),
        cell: (info) => getFechaFormateada(info.row.original.created_at),
      },
      {
        header: 'Acciones',
        cell: (info) => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            {/** Botón para editar*/}
            <Button
              title="Editar reacción"
              className={classes.boton}
              onClick={() =>
                navigate(`/reaccion/editar/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para listar eventos*/}
            <Button
              title="Listar eventos"
              className={classes.boton}
              onClick={() => {
                navigate(`/evento/lista/${info.row.original.id}`);
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
            {/** Botón para concluir reacción*/}
            {info.row.original.estatus_reaccion?.id === 1 && (
              <Button
                title="Concluir reacción"
                className={classes.boton}
                onClick={() =>
                  mutateEstatusReaccion.mutate({
                    id: info.row.original.id,
                    data: {
                      estatus_reaccion_id: 2,
                    },
                    tipo: 'post',
                  })
                }
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            )}
            {/** Botón para reaignar reacción*/}
            {info.row.original.estatus_reaccion?.id === 2 && (
              <>
                <Button
                  title="Reasignar reacción"
                  className={classes.boton}
                  onClick={() =>
                    mutateEstatusReaccion.mutate({
                      id: info.row.original.id,
                      data: {
                        estatus_reaccion_id: 1,
                      },
                      tipo: 'post',
                    })
                  }
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                {/** Botón para crear PDF*/}
                <Button
                  title="PDF"
                  className={classes.boton}
                  onClick={() => mostrarPDF(info.row.original.id)}
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </Button>
              </>
            )}
          </fieldset>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, mutateEstatusReaccion, mostrarPDF]
  );
  /* -------------------------------- return --------------------------------*/
  if (queryReaccion.isLoading || loadingPdf) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container fluid className="p-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>{`${
          estatus_reaccion_id === '1'
            ? 'Reacciones creadas'
            : 'Reacciones concluidas'
        }`}</h2>
      </div>
      <div className={classes.containerTable}>
        <Button
          title="Agregar una nueva reacción"
          className={classes.boton}
          onClick={() => navigate('/reaccion/crear')}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Crear reacción
        </Button>
      </div>
      <CustomTable
        {...{
          data: queryReaccion.data,
          columns,
        }}
      />
    </Container>
  );
};
