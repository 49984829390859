//Componentes externos
import { Button, Container, Form } from 'react-bootstrap';
//Componentes
import { CustomInput } from '../../components/CustomInput/CustomInput';
import { CustomTextArea } from '../../components/CustomTextArea/CustomTextArea';
import { Loading } from '../../components/Loading/Loading';
//Hooks externos
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
//Hooks
import { useMutationData } from '../../hooks/useMutationData';
import { useGetData } from '../../hooks/useGetData';
//Constantes
import { API } from '../../global/global';
//Contexts
import { ImageList, ImageListItem } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { EventoInterface } from '../../interfaces/EventoInterface';
//Estilo
const classes = require('./EventoEditarScreen.module.css').default;

/**
 * EventoEditarScreen
 * @description: Pantalla para editar un evento
 * @date 10/11/2023.
 * @returns JSX del Screen.
 */
export const EventoEditarScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  const { evento_id } = useParams();
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateEvento = useMutationData({
    url: `/evento/${evento_id}`,
    reload: ['evento', `${evento_id}`],
  });
  //Hook para obtener el registro
  const queryEvento = useGetData<EventoInterface>({
    url: `/evento/${evento_id}?includeAll=true`,
    name: ['evento', `${evento_id}`],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryEvento.isLoading || mutateEvento.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="p-4">
      <Form>
        <div className="row">
          <div className="col-12">
            {/* Comentario */}
            <CustomTextArea
              control={control}
              name="comentario"
              label="Comentario"
              value={queryEvento.data?.comentario}
              rules={{
                required: 'Se requiere de un comentario',
                maxLength: {
                  value: 512,
                  message: 'El comentario tiene que ser menor a 512 caracteres',
                },
              }}
            />
            {/* Coordenadas evento */}
            <CustomInput
              control={control}
              name="coordenadas_evento"
              label="Coordenadas del evento"
              value={`${
                queryEvento.data?.latitud && queryEvento.data?.longitud
                  ? queryEvento.data?.latitud +
                    ', ' +
                    queryEvento.data?.longitud
                  : ''
              }`}
              rules={{
                pattern: {
                  value:
                    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                  message:
                    'Las coordenadas del evento no tienen un formato correcto',
                },
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {queryEvento.data?.images && queryEvento.data?.images.length > 0 && (
            <ImageList
              variant="masonry"
              cols={queryEvento.data?.images.length === 1 ? 1 : 2}
              gap={8}
              className="mt-3"
            >
              {queryEvento.data?.images.map((image, index) => (
                <ImageListItem key={`foto-${index}`}>
                  <img
                    src={`${API.replace('api', 'storage')}/evento/${image}`}
                    alt={`${index}`}
                    loading="lazy"
                    style={{
                      width: 200,
                      height: 300,
                      zIndex: -1,
                      borderRadius: 5,
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          {queryEvento.data?.documents &&
            queryEvento.data?.documents.length > 0 && (
              <div className="container">
                {queryEvento.data?.documents
                  .filter(
                    (document) =>
                      document.split('.').pop() === 'mpeg' ||
                      document.split('.').pop() === 'mp4' ||
                      document.split('.').pop() === 'm4a' ||
                      document.split('.').pop() === 'opus' ||
                      document.split('.').pop() === 'ogg' ||
                      document.split('.').pop() === 'wav' ||
                      document.split('.').pop() === 'mp3'
                  )
                  .map((document) => (
                    <div className="row mb-3">
                      <AudioPlayer
                        src={`${API.replace('api', 'storage')}/evento/${document}`}
                        autoPlay={false}
                      />
                    </div>
                  ))}
              </div>
            )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          {queryEvento.data?.audios &&
            queryEvento.data?.audios.length > 0 && (
              <div className="container">
                {queryEvento.data?.audios
                  .filter(
                    (audio) =>
                      audio.split('.').pop() === 'mpeg' ||
                      audio.split('.').pop() === 'mp4' ||
                      audio.split('.').pop() === 'm4a' ||
                      audio.split('.').pop() === 'opus' ||
                      audio.split('.').pop() === 'ogg' ||
                      audio.split('.').pop() === 'wav' ||
                      audio.split('.').pop() === 'mp3'
                  )
                  .map((audio) => (
                    <div className="row mb-3">
                      <AudioPlayer
                        src={`${API.replace('api', 'storage')}/evento/${audio}`}
                        autoPlay={false}
                      />
                    </div>
                  ))}
              </div>
            )}
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateEvento.mutate(
              {
                data: data,
                tipo: 'patch',
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate(`/evento/lista/${queryEvento.data?.reaccion?.id}`);
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
