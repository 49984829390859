import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { API } from "../../global/global";
// Estilos
const classes = require("./Breadcrumb.module.css").default;

const Breadcrumb: React.FC = () => {
  // Hook para obtener la ubicación actual (path) en la aplicación
  const location = useLocation();

  // Hook para obtener parámetros de la URL, como `reaccion_id` o `evento_id`
  const params = useParams<{
    reaccion_id?: string;
    evento_id?: string;
    estatus_reaccion_id?: string;
    usuario_id?: string;
    causa_reaccion_id?: string;
  }>();

  // Estado para manejar si el dropdown está abierto o cerrado
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Estado para almacenar la fecha de creación de una reacción (inicialmente cargada desde `localStorage`)
  const [createdAt, setCreatedAt] = useState<string | null>(
    localStorage.getItem("createdAt")
  );

  // Estado para almacenar el `id` de una reacción (inicialmente cargada desde `localStorage`)
  const [id, setId] = useState<string | null>(
    localStorage.getItem("reactionId")
  ); // Cargar el valor inicial desde localStorage

  // Efecto que se ejecuta cuando `params.reaccion_id` cambia
  useEffect(() => {
    const fetchReactionData = async () => {
      if (params.reaccion_id) {
        try {
          const token = localStorage.getItem("token"); // Obtén el token almacenado en el cliente
          const response = await fetch(
            `${API}/reaccion/${params.reaccion_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setCreatedAt(data.data.created_at); // Almacena la fecha de creación
          setId(data.data.id); // Almacena el ID de la reacción

          // Guarda los datos en `localStorage` para persistir entre recargas
          localStorage.setItem("createdAt", data.data.created_at);
          localStorage.setItem("reactionId", data.data.id);
        } catch (error) {
          console.error("Error fetching reaction data:", error);
        }
      }
    };

    fetchReactionData();
  }, [params.reaccion_id]);

  // Obtiene el año de la fecha de creación almacenada
  const year = new Date(createdAt ?? "").getFullYear();

  // Efecto que se ejecuta cuando cambia el path
  useEffect(() => {
    const lastPath = localStorage.getItem("previousPath");

    // Solo actualizar si el path anterior ha cambiado
    if (lastPath !== location.pathname) {
      //setPreviousPath(lastPath);
      localStorage.setItem("previousPath", location.pathname); // Guarda el nuevo path
    }

    // Dependiendo del path, guarda en `localStorage` si se está en la lista de "Creadas" o "Concluidas"
    const currentPath = location.pathname;
    if (currentPath.includes("/reaccion/lista/1")) {
      if (localStorage.getItem("previousList") !== "Creadas") {
        localStorage.setItem("previousList", "Creadas");
      }
    } else if (currentPath.includes("/reaccion/lista/2")) {
      if (localStorage.getItem("previousList") !== "Concluidas") {
        localStorage.setItem("previousList", "Concluidas");
      }
    }
  }, [location.pathname]);

  // Definimos los breadcrumbs dinámicamente en base al path actual
  const dynamicBreadcrumbs = [{ label: "Inicio", path: "/reaccion/lista/1" }];

  // Breadcrumbs para la lista de reacciones
  if (location.pathname.includes("/reaccion/lista")) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" });

    // Mapeo de estatus
    const estatusReaccionMap: { [key: string]: string } = {
      "1": "Creadas",
      "2": "Concluidas",
    };

    const estatusPathMap: { [key: string]: string } = {
      "1": "/reaccion/lista/1",
      "2": "/reaccion/lista/2",
    };

    const estatusKey = params.estatus_reaccion_id;

    // Verificamos que estatusKey sea una clave válida en el mapa
    if (estatusKey && estatusReaccionMap[estatusKey]) {
      dynamicBreadcrumbs.push({
        label: estatusReaccionMap[estatusKey],
        path: estatusPathMap[estatusKey],
      });
    }
  }

  // Breadcrumb para la página de edición de reacción
  if (location.pathname.includes("/reaccion/editar") && params.reaccion_id) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" });

    // Mapeo de listas anteriores
    const previousList = localStorage.getItem("previousList") || "Concluidas"; // Valor por defecto si es null
    const estatusLabelMap: { [key: string]: string } = {
      Creadas: "Creadas",
      Concluidas: "Concluidas",
    };

    // Agregar el breadcrumb de la lista anterior
    if (estatusLabelMap[previousList]) {
      dynamicBreadcrumbs.push({
        label: estatusLabelMap[previousList],
        path: `/reaccion/lista/${previousList === "Creadas" ? 1 : 2}`,
      });
    }

    // Agrega un breadcrumb con el año dinámico de `createdAt`
    dynamicBreadcrumbs.push({
      label: `Editar Reacción CIO/R/${params.reaccion_id}-${year}`,
      path: `/reaccion/editar/${params.reaccion_id}`,
    });
  }

  // Breadcrumb para crear una reacción
  if (location.pathname.includes("/reaccion/crear")) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" });
    dynamicBreadcrumbs.push({
      label: "Crear Reacción",
      path: "/reaccion/crear",
    });
  }

  // Breadcrumbs para eventos y sus respectivas rutas
  if (location.pathname.includes("/evento/lista")) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" }); // Enlace a la lista de reacciones

    // Verifica la lista anterior de reacciones
    const previousList = localStorage.getItem("previousList") || "Concluidas"; // Valor por defecto
    const isCreadas = previousList === "Creadas";

    // Agrega el breadcrumb de estado anterior
    dynamicBreadcrumbs.push({
      label: isCreadas ? "Creadas" : "Concluidas",
      path: isCreadas ? "/reaccion/lista/1" : "/reaccion/lista/2",
    });

    // Agrega el breadcrumb de la reacción actual
    if (params.reaccion_id) {
      dynamicBreadcrumbs.push({
        label: `Reacción CIO/R/${params.reaccion_id}-${year}`,
        path: `/evento/lista/${params.reaccion_id}`,
      });

      // Agrega el breadcrumb para los eventos
      dynamicBreadcrumbs.push({
        label: `Eventos`,
        path: `/evento/lista/${params.reaccion_id}`,
      });
    }
  }

  // Breadcrumbs para "Editar Evento"
  if (location.pathname.includes("/evento/editar")) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" }); // Enlace a la lista de reacciones

    // Verifica la lista anterior de reacciones
    const previousList = localStorage.getItem("previousList") || "Concluidas"; // Valor por defecto
    const isCreadas = previousList === "Creadas";

    // Agrega el breadcrumb de estado anterior
    dynamicBreadcrumbs.push({
      label: isCreadas ? "Creadas" : "Concluidas",
      path: isCreadas ? "/reaccion/lista/1" : "/reaccion/lista/2",
    });

    // Agrega el breadcrumb de la reacción actual
    dynamicBreadcrumbs.push({
      label: `Reacción CIO/R/${id}-${year}`,
      path: `/evento/lista/${id}`,
    });

    // Agrega el breadcrumb para los eventos
    dynamicBreadcrumbs.push({
      label: `Eventos`,
      path: `/evento/lista/${id}`,
    });

    // Si hay un `evento_id`, agrega el breadcrumb "Editar Evento"
    if (params.evento_id) {
      dynamicBreadcrumbs.push({
        label: `Editar Evento`,
        path: `/evento/editar/${params.evento_id}`,
      });
    }
  }

  // Breadcrumbs para "Crear Evento"
  if (location.pathname.includes("/evento/crear")) {
    dynamicBreadcrumbs.push({ label: "Reacciones", path: "/reaccion/lista/1" });

    // Verifica la lista anterior de reacciones
    const previousList = localStorage.getItem("previousList") || "Concluidas"; // Valor por defecto
    const isCreadas = previousList === "Creadas";

    // Agrega el breadcrumb de estado anterior
    dynamicBreadcrumbs.push({
      label: isCreadas ? "Creadas" : "Concluidas",
      path: isCreadas ? "/reaccion/lista/1" : "/reaccion/lista/2",
    });

    // Agrega el breadcrumb de la reacción actual
    if (params.reaccion_id) {
      dynamicBreadcrumbs.push({
        label: `Reacción CIO/R/${params.reaccion_id}-${year}`,
        path: `/evento/lista/${params.reaccion_id}`,
      });
    }

    // Agrega el breadcrumb para "Crear Evento"
    dynamicBreadcrumbs.push({
      label: "Crear Evento",
      path: `/evento/crear/${params.reaccion_id}`,
    });
  }

  // Breadcrumbs para "Roles"
  if (location.pathname.includes("/rol")) {
    dynamicBreadcrumbs.push({ label: "Roles", path: "/rol" });
  }

  // Breadcrumbs para "Usuarios"
  if (location.pathname.includes("/usuario")) {
    dynamicBreadcrumbs.push({ label: "Usuarios", path: "/usuario" });

    if (params.usuario_id) {
      dynamicBreadcrumbs.push({
        label: `Editar Usuario ${params.usuario_id}`,
        path: `/usuario/editar/${params.usuario_id}`,
      });
    } else if (location.pathname.includes("/usuario/crear")) {
      dynamicBreadcrumbs.push({
        label: "Crear Usuario",
        path: "/usuario/crear",
      });
    }
  }

  // Breadcrumbs para "Causas de Reacción"
  if (location.pathname.includes("/causa_reaccion")) {
    dynamicBreadcrumbs.push({
      label: "Causas de Reacción",
      path: "/causa_reaccion",
    });

    if (params.causa_reaccion_id) {
      dynamicBreadcrumbs.push({
        label: `Editar Causa ${params.causa_reaccion_id}`,
        path: `/causa_reaccion/editar/${params.causa_reaccion_id}`,
      });
    } else if (location.pathname.includes("/causa_reaccion/crear")) {
      dynamicBreadcrumbs.push({
        label: "Crear Causa",
        path: "/causa_reaccion/crear",
      });
    }
  }

  // Ref para el dropdown de las opciones
  const dropdownRef = useRef<HTMLUListElement | null>(null); // Ref para el dropdown

  // Función para abrir o cerrar el dropdown
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Función para seleccionar una opción en el dropdown
  const handleOptionSelect = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav>
      <ul className={classes.navbar}>
        {dynamicBreadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            {breadcrumb.label === "Reacciones" ? (
              <>
                <span
                  onClick={handleDropdownToggle}
                  style={{ cursor: "pointer", color: "#ffffff" }}
                >
                  {breadcrumb.label}
                </span>
                {isDropdownOpen && (
                  <ul className={classes.dropdown} ref={dropdownRef}>
                    <li>
                      <Link to="/reaccion/lista/1" onClick={handleOptionSelect}>
                        Creadas
                      </Link>
                    </li>
                    <li>
                      <Link to="/reaccion/lista/2" onClick={handleOptionSelect}>
                        Concluidas
                      </Link>
                    </li>
                  </ul>
                )}
              </>
            ) : (
              <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default Breadcrumb;
