//Componentes externos
import { Button, Container, Form } from "react-bootstrap";
//Componentes
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { Loading } from "../../components/Loading/Loading";
import { CustomSelect } from "../../components/CustomSelect/CustomSelect";
//Hooks externos
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
//Estilo
const classes = require("./CausaReaccionCrearScreen.module.css").default;

/**
 * CausaReaccionCrearScreen
 * @description: Pantalla para crear un causa reaccion
 * @date 16/07/2023.
 * @returns JSX del Screen.
 */
export const CausaReaccionCrearScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook del formulario
  const { control, handleSubmit } = useForm();
  //Hook para realizar la petición
  const mutateCausaReaccion = useMutationData({ url: "/causa_reaccion" });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (mutateCausaReaccion.isPending) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="my-4">
      <Form>
        <div className="row">
          <div className="col-12">
            {/* Nombre */}
            <CustomInput
              control={control}
              name="nombre"
              label="Nombre"
              rules={{
                required: "Se requiere de un nombre",
                maxLength: {
                  value: 150,
                  message: "El nombre tiene que ser menor a 150 caracteres",
                },
              }}
            />
            {/* Nivel de riesgo */}
            <CustomSelect
              control={control}
              name="nivel_riesgo"
              label="Nivel de riesgo"
              labelField={"label"}
              valueField={"value"}
              returnFormat={"label"}
              data={[
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
                { value: 5, label: 5 },
              ]}
              rules={{
                required: "Se requiere de un nivel de riesgo",
              }}
            />
          </div>
        </div>
        <Button
          className={`${classes.boton} mt-3`}
          onClick={handleSubmit((data: any) => {
            mutateCausaReaccion.mutate(
              {
                data: data,
                tipo: "post",
              },
              {
                onSuccess: () => {
                  //Se redirige en caso de exito
                  navigate("/causa_reaccion");
                },
              }
            );
          })}
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
