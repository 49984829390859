//React
import { useMemo } from "react";
//Componentes externos
import { Button, Container } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import {
  faSquarePlus,
  faPen,
  faTrash,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Componentes
import CustomTable from "../../components/CustomTable/CustomTable";
import { Loading } from "../../components/Loading/Loading";
//Hooks externos
import { useNavigate } from "react-router-dom";
//Hooks
import { useMutationData } from "../../hooks/useMutationData";
import { useGetData } from "../../hooks/useGetData";
//Interfaces
import { CausaReaccionInterface } from "../../interfaces/CausaReaccionInterface";
//Estilo
const classes = require("./CausaReaccionListaScreen.module.css").default;

export const CausaReaccionListaScreen = () => {
  /* ----------------------------- Hooks ------------------------------------*/
  //Hook para cambiar el estatus del registro
  const mutateCausaReaccion = useMutationData({
    url: "/causa_reaccion",
    reload: ["causa_reaccion"],
  });
  //Se encarga de traer la información
  const queryCausaReaccion = useGetData<CausaReaccionInterface[]>({
    url: "/causa_reaccion",
    name: ["causa_reaccion"],
  });
  //Se encarga de redigir las pantallas
  const navigate = useNavigate();
  /* ------------------------------ Funciones -------------------------------*/
  const columns = useMemo<ColumnDef<CausaReaccionInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        accessorKey: "nivel_riesgo",
        header: () => "Nivel de riesgo",
      },
      {
        id: "estatus",
        header: "Estatus",
        accessorFn: (row) => (row.estatus === 1 ? "ACTIVO" : "ELIMINADO"),
        cell: (info) => info.getValue(),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            <Button
              title="Editar causa reacción"
              className={classes.boton}
              onClick={() =>
                navigate(`/causa_reaccion/editar/${info.row.original.id}`)
              }
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para reactivar*/}
            {info.row.original.estatus === 0 && (
              <Button
                title="Reactivar causa reacción"
                className={classes.boton}
                onClick={() =>
                  mutateCausaReaccion.mutate({
                    id: info.row.original.id,
                    data: { estatus: 1 },
                    tipo: "patch",
                  })
                }
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
            )}
            {/** Botón para eliminar*/}
            {info.row.original.estatus === 1 && (
              <Button
                title="Eliminar causa reaccion"
                className={classes.boton}
                onClick={() =>
                  mutateCausaReaccion.mutate({
                    id: info.row.original.id,
                    data: { estatus: 0 },
                    tipo: "delete",
                  })
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </fieldset>
        ),
      },
    ],
    [navigate, mutateCausaReaccion]
  );
  /* -------------------------------- return --------------------------------*/
  //Return loading si aún se está haciendo la petición
  if (queryCausaReaccion.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <Container className="my-4">
      {/* Subtítulo */}
      <div className="row mt-3">
        <h2 className={classes.subtitulo}>Causas de reacción</h2>
      </div>
      <div className={classes.containerTable}>
        <Button
          title="Agregar causa reacción"
          className={classes.boton}
          onClick={() => navigate("/causa_reaccion/crear")}
        >
          <FontAwesomeIcon icon={faSquarePlus} /> Agregar causa reacción
        </Button>
      </div>
      {queryCausaReaccion.data && (
        <CustomTable
          {...{
            data: queryCausaReaccion.data,
            columns,
          }}
        />
      )}
    </Container>
  );
};
